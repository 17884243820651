import { InputBase, styled, Tab } from '@mui/material';
import MainLogo from '@assets/images/teulu-logo.svg';

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 40,
    backgroundColor: theme.palette.grey[50],
    marginRight: 20,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[100],
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    fontSize: 14,
}));

export const Logo = styled('img')(({ theme }) => ({
    height: '35px',
    marginRight: theme.spacing(2),
    content: `url(${MainLogo})`,
}));

export const MenuTab = styled(Tab)(({ theme }) => ({
    padding: '23px 16px',
    textTransform: 'none',

    '&.Mui-selected': {
        color: theme.palette.text.primary,
        fontWeight: 700,
    },
}));
