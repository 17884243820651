import { IHealth } from '@interfaces/IHealth';
export const healthDataInitialState: IHealth = {
    healthInsuranceCard: {
        typeOfHealthInsurance: '',
        healthInsuranceCompany: '',
        insuranceNumber: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        benefits: '',
    },
    voluntaryHealthInsurance: {
        healthInsuranceCompany: '',
        insuranceNumber: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        benefits: '',
    },
    suplementHospitalInsurance: {
        healthInsuranceCompany: '',
        insuranceNumber: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        benefits: '',
    },
    nursingCareInsurance: {
        healthInsuranceCompany: '',
        insuranceNumber: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        telephone: '',
        benefits: '',
    },
    bloodTypeCard: {
        bloodType: '',
        rhesusFactor: '',
    },
    vaccinationDetails: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        placeOfBirth: '',
    },
    allergyCard: {
        hypersensitivityToTheSubstances: [],
        dateOfLastCheck: '',
    },
    disabilityCard: {
        firstName: '',
        lastName: '',
        birthday: '',
        disabilityLevel: '',
        validFrom: '',
        validTo: '',
        accompanyingPerson: '',
        mark: [],
    },
    emergencyCard: {
        firstName: '',
        lastName: '',
        birthday: '',
        emergencyContactFirstName: '',
        emergencyContactLastName: '',
        emergencyContactPhone: '',
        diseases: '',
        allergies: '',
        medication: '',
    },
    diabeticCard: {
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        emergencyContactFirstName: '',
        emergencyContactLastName: '',
        emergencyContactPhone: '',
    },
    pacemakerCard: {
        deviceType: '',
        modelNumber: '',
        serialNumber: '',
        implantationDate: '',
        responsiblePhysician: '',
    },
    organDonorCard: {
        allowDonorOrgans: '',
        allowDonorOrgansException: '',
        allowDonorOrgansExceptionComment: '',
        allowDonorOrgansForOnly: '',
        allowDonorOrgansForOnlyComment: '',
        notAllowedDonorOrgans: '',
        personFirstName: '',
        personLastName: '',
        personTelephone: '',
        personStreet: '',
        personStreetNumber: '',
        personPostcode: '',
        personCity: '',
        firstName: '',
        lastName: '',
        street: '',
        streetNumber: '',
        postcode: '',
        city: '',
        dateOfBirth: '',
        dateOfSignature: '',
    },
    treatingPhysicians: {
        typeOfMedical: [],
    },
    medication: {
        medicationTable: [],
    },
    surgeries: {
        surgeriesTable: [],
    },
    medicalRecords: {
        medicalRecordsTable: [],
    },
};
