import React, { useEffect } from 'react';
import { useUser } from '@contexts/UserProvider';
import useContactsStore from '@stores/useContactsStore';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import MainLayout from './MainLayout';
import useProfilesStore from '@stores/useProfilesStore';
import { useAccountProfiles } from '@hooks/queries/profiles/queries';
import { useContacts, useGetInviterByInvitationCode } from '@hooks/queries/contacts/queries';
import { DOCUMENTS_QUERY_KEY, useUserDocuments } from '@hooks/queries/documents/queries';
import { useAccountSettings } from '@hooks/queries/settings/queries';
import { useQueryClient } from '@tanstack/react-query';

const Wizard = React.lazy(() => import('../../pages/Wizard/Wizard'));
const Documents = React.lazy(() => import('../../pages/Documents/Documents'));
const Contacts = React.lazy(() => import('../../pages/Contacts/Contacts'));
const Account = React.lazy(() => import('../../pages/Account/Account'));

const AuthenticatedLayout = () => {
    const user = useUser();
    const { hideNavigation, setUserSettingsDetails } = useUserSettingStore();
    const { setInviter } = useContactsStore();
    const getInviterByInvitationCode = useGetInviterByInvitationCode();
    const { setActiveProfile, activeProfile, isSharedProfile } = useProfilesStore();
    const token = user.currentUser?.accessToken;
    const navigate = useNavigate();
    const accountId = user.currentUser?.accountId;
    const queryClient = useQueryClient();

    const { isSuccess: contactsSuccess } = useContacts(token!);
    const { data: fetchedSettings } = useAccountSettings(token!);
    useAccountProfiles(token!, { enabled: !!token && contactsSuccess });
    useUserDocuments(token!);

    useEffect(() => {
        if (fetchedSettings) {
            setUserSettingsDetails(fetchedSettings);
        }
    }, [fetchedSettings]);

    useEffect(() => {
        if (accountId) {
            const invitationCode = sessionStorage.getItem('invitationCode');

            const getInviter = async () => {
                if (invitationCode) {
                    const inviter = await getInviterByInvitationCode.mutateAsync({
                        invitationCode: invitationCode,
                        token: token!,
                    });
                    setInviter(inviter);
                }
            };

            void getInviter();
        }
    }, [accountId]);

    useEffect(() => {
        const accountId = user.currentUser?.accountId;
        if (accountId) {
            setActiveProfile(accountId);
        }
    }, []);

    useEffect(() => {
        void queryClient.invalidateQueries({ queryKey: [DOCUMENTS_QUERY_KEY] });

        if (isSharedProfile) {
            navigate('/documents');
        }
    }, [activeProfile, queryClient, isSharedProfile, navigate]);

    return (
        <MainLayout hideNavigation={hideNavigation}>
            <Routes>
                <Route path="/wizards" element={<Wizard />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/account/:section" element={<Account />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </MainLayout>
    );
};

export default AuthenticatedLayout;
