// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { IUserContext } from '@interfaces/IUserContext';
import { IUser } from '@interfaces/IUser';
import { IProps } from '@interfaces/IProps';
import { COGNITO } from '@config/cognitoConfig';
import { initializeApi } from 'src/common/api';

const userContextState = {
    currentUser: {
        accessToken: '',
        refreshToken: '',
        isLoggedIn: false,
        accountId: '',
        email: '',
    },
    login: () => '',
    logout: () => '',
    signup: () => '',
    setRegistrated: () => '',
    handleGetUser: () => '',
};

interface CustomJwtPayload extends JwtPayload {
    accountId: string;
    email: string;
}

const UserContext = React.createContext<IUserContext>(userContextState);

interface IToken {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
}

const UserProvider = ({ children }: IProps) => {
    const params = new URLSearchParams(location.search);
    const codeUrl = params.get('code');
    const [currentUser, setCurrentUser] = useState<IUser | null>();
    const [registrated, setRegistrated] = useState(false);
    const tokenCheckInterval = useRef<NodeJS.Timeout>();

    const navigate = useNavigate();

    useEffect(() => {
        setCurrentUser(null);
    }, []);

    useEffect(() => {
        initializeApi(logout);
    }, []);

    const isTokenValid = (token: string): boolean => {
        try {
            const decoded = jwtDecode<JwtPayload>(token);
            if (!decoded.exp) return false;
            return decoded.exp * 1000 > Date.now() + 5 * 60 * 1000;
        } catch {
            return false;
        }
    };

    const handleGetUser = async (code: string, grant = '') => {
        try {
            const tokens = await getTokens(code, grant);
            if (tokens?.access_token) {
                const decoded = jwtDecode<CustomJwtPayload>(tokens.access_token);
                const userData = {
                    accessToken: tokens.access_token,
                    refreshToken: tokens.refresh_token,
                    isLoggedIn: true,
                    accountId: decoded.accountId,
                    email: decoded.email,
                };
                setCurrentUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
            }
        } catch (error) {
            console.debug('Error exchanging code for tokens:', error);
        }
    };

    const refreshUserTokens = async (refreshToken: string) => {
        const url = process.env.REACT_APP_AUTH_TOKEN!;
        try {
            const body = new URLSearchParams({
                grant_type: 'refresh_token',
                client_id: COGNITO.APP_CLIENT_ID!,
                refresh_token: refreshToken,
            });

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            return (await response.json()) as IToken;
        } catch (error) {
            console.log('Token refresh failed:', error);
            logout();
            return null;
        }
    };

    const getTokens = async (code: string, grant = '') => {
        if (code) {
            try {
                const url = process.env.REACT_APP_AUTH_TOKEN!;
                const body = new URLSearchParams({
                    grant_type: grant ? grant : registrated ? 'refresh_token' : 'authorization_code',
                    client_id: COGNITO.APP_CLIENT_ID!,
                    code: code,
                    redirect_uri: COGNITO.REDIRECT_SIGN_IN!,
                    refresh_token: currentUser?.refreshToken ?? '',
                });

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: body.toString(),
                });

                return (await response.json()) as IToken;
            } catch (error) {
                console.error('Error getting tokens:', error);
                logout();
                return null;
            }
        }
        return null;
    };

    const login = () => {
        const url = `https://${COGNITO.DOMAIN}/login?client_id=${COGNITO.APP_CLIENT_ID}&response_type=code&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}`;
        window.location.href = url;
    };

    const signup = () => {
        const url = `https://${COGNITO.DOMAIN}/signup?client_id=${COGNITO.APP_CLIENT_ID}&response_type=code&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}`;
        window.location.href = url;
    };

    const logout = useCallback(() => {
        if (tokenCheckInterval.current) {
            clearInterval(tokenCheckInterval.current);
        }
        localStorage.removeItem('user');
        localStorage.removeItem('code');
        setCurrentUser(null);
        window.location.href = 'https://www.teulu.de';
    }, [navigate]);

    const startTokenExpirationCheck = useCallback(() => {
        if (tokenCheckInterval.current) {
            clearInterval(tokenCheckInterval.current);
        }

        tokenCheckInterval.current = setInterval(() => {
            const storedUser = localStorage.getItem('user');
            if (!storedUser) {
                logout();
                return;
            }

            const checkTokenValidity = async () => {
                const userData: IUser = JSON.parse(storedUser) as IUser;

                if (!isTokenValid(userData.accessToken)) {
                    if (userData.refreshToken) {
                        try {
                            const tokens = await refreshUserTokens(userData.refreshToken);
                            if (tokens) {
                                const decoded = jwtDecode<CustomJwtPayload>(tokens.access_token);
                                const newUserData = {
                                    accessToken: tokens.access_token,
                                    refreshToken: tokens.refresh_token,
                                    isLoggedIn: true,
                                    accountId: decoded.accountId,
                                    email: decoded.email,
                                };
                                setCurrentUser(newUserData);
                                localStorage.setItem('user', JSON.stringify(newUserData));
                            } else {
                                logout();
                            }
                        } catch (error) {
                            console.debug('Error during token refresh:', error);
                            logout();
                        }
                    } else {
                        logout();
                    }
                }
            };

            void checkTokenValidity();
        }, 60000);
    }, []);

    useEffect(() => {
        const initializeAuth = async () => {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                const userData: IUser = JSON.parse(storedUser) as IUser;
                if (isTokenValid(userData.accessToken)) {
                    setCurrentUser(userData);
                    startTokenExpirationCheck();
                } else if (userData.refreshToken) {
                    try {
                        const tokens = await refreshUserTokens(userData.refreshToken);
                        if (tokens) {
                            const decoded = jwtDecode<CustomJwtPayload>(tokens.access_token);
                            const newUserData = {
                                accessToken: tokens.access_token,
                                refreshToken: tokens.refresh_token,
                                isLoggedIn: true,
                                accountId: decoded.accountId,
                                email: decoded.email,
                            };
                            setCurrentUser(newUserData);
                            localStorage.setItem('user', JSON.stringify(newUserData));
                            startTokenExpirationCheck();
                        }
                    } catch (error) {
                        console.debug('Error refreshing token:', error);
                        logout();
                    }
                } else {
                    logout();
                }
            }
        };

        void initializeAuth();

        return () => {
            if (tokenCheckInterval.current) {
                clearInterval(tokenCheckInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        if (codeUrl) {
            localStorage.setItem('code', codeUrl);
            void handleGetUser(codeUrl);
        }
    }, [codeUrl]);

    const value = useMemo(
        () => ({
            currentUser,
            login,
            logout,
            signup,
            setRegistrated,
            handleGetUser,
        }),
        [currentUser, login, logout, signup, setRegistrated, handleGetUser],
    );

    return <UserContext.Provider value={value as IUserContext}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUser = () => {
    const context = React.useContext(UserContext);

    if (context === null) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};
