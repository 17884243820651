import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ProfileState {
    activeProfile: string;
    isSharedProfile: boolean;

    setActiveProfile: (id: string) => void;
    setIsSharedProfile: (isShared: boolean) => void;
}

const useProfilesStore = create<ProfileState>()(
    devtools((set) => ({
        activeProfile: '',
        isSharedProfile: false,

        setActiveProfile: (id: string) => {
            set({ activeProfile: id });
        },

        setIsSharedProfile: (isShared: boolean) => {
            set({ isSharedProfile: isShared });
        },
    })),
);

export default useProfilesStore;
