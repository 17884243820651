// // © 2024 finBalance - Ingo.Brenckmann@finbalance.de
import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/index';
import './App.css';
import initI18n from './i18n';

import UserProvider from '@contexts/UserProvider';
import NotificationProvider from '@contexts/NotificationProvider';

import PublicRoute from '@components/publicPrivateRoute/PublicRoute';
import PrivateRoute from '@components/publicPrivateRoute/PrivateRoute';

import WelcomePage from './pages/WelcomePage/WelcomePage';
import Loading from '@components/loading/Loading';
import ErrorBoundary from '@components/errorBoundary';
import AuthenticatedLayout from '@components/layout/AuthenticatedLayout';
import { useInvitationCode } from '@hooks/useInvitationCode';
import ErrorComponent from './error/Error';

async function setupApp() {
    await initI18n();
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            retry: 1,
        },
    },
});

function App() {
    const { invitationCode } = useInvitationCode();

    useEffect(() => {
        if (invitationCode) {
            console.log('Using invitation code:', invitationCode);
        }
    }, [invitationCode]);

    useEffect(() => {
        void setupApp();
    }, []);

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <NotificationProvider>
                            <UserProvider>
                                <ErrorBoundary fallback={<ErrorComponent />}>
                                    <Suspense fallback={<Loading open={false} />}>
                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <PublicRoute>
                                                        <WelcomePage />
                                                    </PublicRoute>
                                                }
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    <PrivateRoute>
                                                        <AuthenticatedLayout />
                                                    </PrivateRoute>
                                                }
                                            />
                                        </Routes>
                                    </Suspense>
                                </ErrorBoundary>
                            </UserProvider>
                        </NotificationProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </div>
    );
}

export default App;
