
import SignButtons from '../signButtons/SignButtons';
import { AppBar, Box, Container, Toolbar } from '@mui/material';

import theme from 'src/theme';
import * as Styled from './styles';
import './Header.css';

const Header = () => {

    return (

        <AppBar
            position="static"
            color="secondary"
            sx={{
                boxShadow: 'none',
                borderBottom: '1px solid #D7D7F0',
                background: theme.palette.common.white,
                '@media print': {
                    display: 'none',
                },
            }}
        >
            <Container
                sx={{
                    maxWidth: '1366px !important',
                }}
                id="top"
            >
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Styled.Logo aria-label="logo" />
                    </Box>
                    <SignButtons />
                </Toolbar>    
            </Container>
        </AppBar>
    );
};

export default Header;
