import { IDocument } from '@interfaces/IDocument';
import { get as getData, post as postData, update as updateData, deleteData } from '../common/api';
import { HospitalChecklistItem, WizardFormData } from '@stores/useWizzardStore';

interface UploadUrlResponse {
    url: string;
}

export const getUserDocuments = async (token: string, profileId?: string): Promise<IDocument[]> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents`;
    const switchAccountHeader = profileId ?? '';
    return await getData<IDocument[]>(url, token, switchAccountHeader);
};

export const getDocumentById = async (id: string, token: string, profileId?: string): Promise<IDocument> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;
    const switchAccountHeader = profileId ?? '';
    return await getData<IDocument>(url, token, switchAccountHeader);
};

export const createDocument = async <K extends keyof WizardFormData[keyof WizardFormData]>(
    step: K,
    payload: Partial<WizardFormData[K]>,
    token: string,
): Promise<{ id: string }> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents`;

    const filledKeys = Object.keys(payload).filter((key) => payload[key as keyof WizardFormData[K]] !== '');

    const metadata = {
        docType: step,
        docReviewDate: undefined as string | undefined,
    };

    if ('validTo' in payload && typeof payload.validTo === 'string') {
        metadata.docReviewDate = payload.validTo;
    }

    const data = {
        metadata,
        payload: payload,
        payloadState: {
            filled: filledKeys,
        },
    };

    return await postData<{ id: string }, typeof data>(url, data, token);
};

export const updateDocument = async <K extends keyof WizardFormData[keyof WizardFormData]>(
    id: string,
    step: K,
    payload: Partial<WizardFormData[K]>,
    token: string,
): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;

    const filledKeys = Object.keys(payload).filter((key) => payload[key as keyof WizardFormData[K]] !== '');

    const metadata = {
        docType: step,
        docReviewDate: undefined as string | undefined,
    };

    if ('validTo' in payload && typeof payload.validTo === 'string') {
        metadata.docReviewDate = payload.validTo;
    }

    const data = {
        metadata,
        payload: payload,
        payloadState: {
            filled: filledKeys,
        },
    };

    await updateData(url, data, token);
};

export const deleteDocument = async (id: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}`;
    await deleteData(url, token);
};

export const getFileUploadUrl = async (id: string, token: string): Promise<string | undefined> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}/file-upload-url`;

    try {
        const response = await getData<UploadUrlResponse>(url, token);
        return response?.url;
    } catch (error) {
        console.error('Error getting file upload URL:', error);
        return undefined;
    }
};

export const uploadDocumentFile = async (url: string, file: File): Promise<void> => {
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'file/pdf',
        },
        body: file,
    });
};

export const deleteDocumentFile = async (id: string, token: string): Promise<string | undefined> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}/file`;

    try {
        await deleteData(url, token);
        return 'Success';
    } catch (error) {
        console.error('Error deleting document file:', error);
        return undefined;
    }
};

export const getDocumentThumbnail = async (id: string, token: string, profileId?: string): Promise<string | undefined> => {
    const url = `${process.env.REACT_APP_API_URL}/documents/v1/documents/${id}/thumbnail`;
    const switchAccountHeader = profileId ?? '';

    const headers: Record<string, string> = {
        Authorization: `Bearer ${token}`,
        Accept: 'image/png',
    };

    if (switchAccountHeader) {
        headers['x-teulu-profile-account-id'] = switchAccountHeader;
    }

    try {
        const response: Response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
        const base64String: string = btoa(new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));

        return `data:image/png;base64,${base64String}`;
    } catch (error) {
        console.error('Failed to fetch thumbnail:', error);
        return undefined;
    }
};

export const getHospitalChecklist = async (token: string, profileId?: string): Promise<HospitalChecklistItem[]> => {
    const url = `${process.env.REACT_APP_API_URL}/assets/v1/assets?asset_group=hospital-admission-checklist`;
    const switchAccountHeader = profileId ?? '';

    return await getData<HospitalChecklistItem[]>(url, token, switchAccountHeader);
};

export const deleteHospitalChecklistItem = async (id: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/assets/v1/assets/${id}`;
    await deleteData(url, token);
};

export const createHospitalChecklistItem = async (payload: Partial<HospitalChecklistItem>, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/assets/v1/assets`;

    await postData(url, payload, token);
};

export const updateHospitalChecklistItem = async (id: string, payload: Partial<HospitalChecklistItem>, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/assets/v1/assets/${id}`;

    await updateData(url, payload, token);
};
