// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>
import MainLayout from '@components/layout/MainLayout';
import { Typography } from '@mui/material';

const WelcomePage = () => {

    return (
        <div data-testid="welcome-page">
            <MainLayout>
                <Typography variant="caption" color='lightgrey'>
                    Technology out of control - finBalance (c) 2025
                </Typography>
            </MainLayout>
        </div>
    );
};

export default WelcomePage;
