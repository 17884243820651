import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as settingsApi from 'src/api/settingsApi';
import { update as updateData } from '../../../common/api';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import { useNotification } from '@contexts/NotificationProvider';
import { useTranslation } from 'react-i18next';

export const useAccountSettings = (token: string, options = {}) => {
    return useQuery({
        queryKey: ['userSettingsDetails'],
        queryFn: async () => {
            return await settingsApi.fetchUserSettingsDetails(token);
        },
        enabled: true,
        ...options,
    });
};

export const useSaveUserSettings = (token: string) => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { userSettingsDetails } = useUserSettingStore();

    return useMutation({
        mutationFn: async () => {
            const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts/settings`;
            await updateData(url, userSettingsDetails, token);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['userSettingsDetails'] });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_save_settings'), 'error');
            console.debug('Save settings error:', error);
        },
    });
};

export const useAccount = (token: string, options = {}) => {
    return useQuery({
        queryKey: ['account'],
        queryFn: async () => {
            return await settingsApi.getAccount(token);
        },
        enabled: true,
        ...options,
    });
};
