import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface Salutation {
    firstName: string;
    lastName: string;
    displayName: string;
    termsAndConditionsAccepted: boolean | undefined;
    email: string;
}

export interface UserSettingsDetails {
    gender: string;
    martialStatus: string;
    isHoldingPower: boolean;
    directivesIntro: boolean;
    nursingIntro: boolean;
    vehiclesIntro: boolean;
    hasCar: boolean | undefined;
    hasLicense: boolean | undefined;
    directivesAppInfo: boolean;
    notRelevantSteps: string[];
    doneSteps: string[];
    userTotalSteps: number;
    doneWizards: string[];
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    dateOfBirth: string;
    placeOfBirth: string;
    nationality: string;
    religion: string;
    street: string;
    streetNumber: string;
    postcode: string;
    city: string;
    country: string;
    language: string;
    startingView: string;
    hasFuneralInsuranceContract: boolean | null;
    hasFuneralInsurance: boolean | null;
    hasMinorChildren: boolean | null;
    hasGraveSite: boolean | undefined;
    hasVoluntaryHealthInsurance: boolean | undefined;
    hasSupplementaryHospitalInsurance: boolean | undefined;
    hasNursingCareInsurance: boolean | undefined;
    hasAllergies: boolean | undefined;
    hasDisability: boolean | undefined;
    hasDiabetes: boolean | undefined;
    hasPacemaker: boolean | undefined;
    donatesOrgans: boolean | undefined;
    takesRegularMedicine: boolean | undefined;
    hadSurgeries: boolean | undefined;
    aiAllowed: boolean | undefined;
}

interface UserSettingsStore {
    salutation: Salutation;
    tab: string;
    userSettingsDetails: UserSettingsDetails;
    isLoading: boolean;
    error: string | null;
    hideNavigation: boolean;
    userSettingsLoading: boolean;
    setTab: (tab: string) => void;
    updateSalutation: (data: Partial<Salutation>) => void;
    updateGender: (gender: string) => void;
    updateMartialStatus: (status: string) => void;
    updateReligion: (religion: string) => void;
    updateNationality: (national: string) => void;
    updateUserSettingsField: <T extends keyof UserSettingsDetails>(field: T, value: UserSettingsDetails[T]) => void;
    addNotRelevantStep: (step: string) => void;
    removeNotRelevantStep: (step: string) => void;
    addDoneStep: (step: string) => void;
    removeDoneStep: (step: string) => void;
    setUserTotalSteps: (length: number) => void;
    setWizardDone: (step: string) => void;
    setHideNavigation: (hide: boolean) => void;
    setUserSettingsDetails: (data: UserSettingsDetails) => void;
}

export const useUserSettingStore = create<UserSettingsStore>()(
    devtools((set) => ({
        salutation: {
            firstName: '',
            lastName: '',
            displayName: '',
            termsAndConditionsAccepted: false,
            email: '',
        },
        tab: 'Wizards',
        userSettingsDetails: {
            gender: 'Choose',
            martialStatus: 'Choose',
            isHoldingPower: false,
            directivesIntro: false,
            nursingIntro: false,
            vehiclesIntro: false,
            hasCar: undefined,
            hasLicense: undefined,
            directivesAppInfo: false,
            notRelevantSteps: [],
            doneSteps: [],
            userTotalSteps: 0,
            doneWizards: [],
            firstName: '',
            lastName: '',
            displayName: '',
            email: '',
            dateOfBirth: '',
            placeOfBirth: '',
            nationality: '',
            religion: '',
            street: '',
            streetNumber: '',
            postcode: '',
            city: '',
            country: '',
            language: '',
            startingView: '',
            hasFuneralInsuranceContract: null,
            hasFuneralInsurance: null,
            hasMinorChildren: null,
            hasGraveSite: undefined,
            hasVoluntaryHealthInsurance: undefined,
            hasSupplementaryHospitalInsurance: undefined,
            hasNursingCareInsurance: undefined,
            hasAllergies: undefined,
            hasDisability: undefined,
            hasDiabetes: undefined,
            hasPacemaker: undefined,
            donatesOrgans: undefined,
            takesRegularMedicine: undefined,
            hadSurgeries: undefined,
            aiAllowed: undefined,
        },
        hideNavigation: false,
        isLoading: false,
        error: null,
        userSettingsLoading: true,

        setTab: (newTab) => {
            set({ tab: newTab });
        },

        updateSalutation: (data) =>
            set((state) => ({
                salutation: {
                    ...state.salutation,
                    ...data,
                },
            })),

        updateGender: (gender) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    gender,
                },
            })),

        updateMartialStatus: (status) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    martialStatus: status,
                },
            })),

        updateUserSettingsField: (field, value) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    [field]: value,
                },
            })),

        updateNationality: (national: string) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    nationality: national,
                },
            })),

        updateReligion: (religion: string) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    religion: religion,
                },
            })),

        addNotRelevantStep: (step) =>
            set((state) => {
                const currentSteps = state.userSettingsDetails?.notRelevantSteps || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        notRelevantSteps: currentSteps.includes(step) ? currentSteps : [...currentSteps, step],
                    },
                };
            }),

        removeNotRelevantStep: (step) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    notRelevantSteps: state.userSettingsDetails.notRelevantSteps.filter((s) => s !== step),
                },
            })),

        addDoneStep: (step: string) =>
            set((state) => {
                const currentSteps = state.userSettingsDetails?.doneSteps || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        doneSteps: currentSteps.includes(step) ? currentSteps : [...currentSteps, step],
                    },
                };
            }),

        removeDoneStep: (step) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    doneSteps: state.userSettingsDetails?.doneSteps?.filter((s) => s !== step),
                },
            })),

        setUserTotalSteps: (steps) =>
            set((state) => ({
                userSettingsDetails: {
                    ...state.userSettingsDetails,
                    userTotalSteps: steps,
                },
            })),

        setWizardDone: (step) =>
            set((state) => {
                const currentDoneWizards = state.userSettingsDetails?.doneWizards || [];
                return {
                    userSettingsDetails: {
                        ...state.userSettingsDetails,
                        doneWizards: currentDoneWizards.includes(step) ? currentDoneWizards : [...currentDoneWizards, step],
                    },
                };
            }),

        setHideNavigation: (hide) =>
            set(() => ({
                hideNavigation: hide,
            })),

        setUserSettingsDetails: (data) =>
            set(() => ({
                userSettingsDetails: data,
            })),
    }))
);
