import { AddContactResponse, AvatarUrl, IContact, InviterResponse } from '@interfaces/contacts/contacts';
import { get as getData, post as postData, update as updateData, deleteData } from '../common/api';

export const getContacts = async (token: string): Promise<IContact[]> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
    const response = await getData<IContact[]>(url, token);
    return response;
};

export const addContact = async (contactData: Omit<IContact, 'id'>, token: string): Promise<AddContactResponse> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts`;
    const response = await postData<AddContactResponse, Omit<IContact, 'id'>>(url, contactData, token);
    return response;
};

export const updateContact = async (id: string, updatedContact: Partial<IContact>, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
    await updateData(url, updatedContact, token);
};
export const deleteContact = async (id: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}`;
    await deleteData(url, token);
};

export const getAvatar = async (id: string, token: string): Promise<string | undefined> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/avatar`;
    const response = await getData(url, token, undefined, true);

    return response as string;
};

export const deleteContactAvatar = async (id: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/avatar`;
    await deleteData(url, token);
}

export const sendInvitation = async (id: string, sendMessage: { message: string; assignedRole: string }, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/invite`;
    const data = sendMessage;
    await postData(url, data, token);
};

export const getContactAvatarUploadUrl = async (contactId: string, token: string): Promise<AvatarUrl> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${contactId}/avatar-upload-url`;
    const response = await getData<AvatarUrl>(url, token);
    return response;
};

export const uploadContactAvatar = async (url: string, image: File): Promise<void> => {
    await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'image/*',
        },
        body: image,
    });
};

export const fetchAvatarImage = async (avatarUrl: string): Promise<string> => {
    const imageResponse = await fetch(avatarUrl, {
        cache: 'reload',
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Expires: '0',
        },
    });

    if (imageResponse.status === 404) {
        throw new Error('Avatar image not found');
    }

    const imageBlob = await imageResponse.blob();
    return URL.createObjectURL(imageBlob);
};

export const getInviterByInvitationCode = async (invitationCode: string, token: string): Promise<InviterResponse> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/get-inviter-by-invitation-code?invitation_code=${invitationCode}`;
    const response = await getData<InviterResponse>(url, token);
    return response;
};

export const respondInvitation = async (respond: string, invitationCode: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/respond-invitation?invitation_code=${invitationCode}`;
    await postData(url, { response: respond }, token);
};

export const updatePermissions = async (id: string, role: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/permissions`;
    await updateData(url, {
        role,
    }, token);
}

export const deletePermissions = async (id: string, token: string): Promise<void> => {
    const url = `${process.env.REACT_APP_API_URL}/contacts/v1/contacts/${id}/permissions`;
    await deleteData(url, token);
}