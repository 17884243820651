// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer: React.FunctionComponent = () => {


   

    return (
        <footer>
        </footer>
    );
};

export default Footer;
