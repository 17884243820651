import { Box, IconButton, Tooltip, Menu, MenuItem, PopoverVirtualElement, Typography, Stack, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from 'src/theme';
import { useUser } from '@contexts/UserProvider';
import { useUserSettingStore } from '@stores/useUserSettingsStore';
import ActiveIcon from '@assets/images/active.svg';
import useProfilesStore from '@stores/useProfilesStore';
import { useAccountProfiles } from '@hooks/queries/profiles/queries';
interface IMenu {
    button: React.ReactNode;
    anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
    open: boolean;
    onClose: () => void;
    onOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    buttonStyle?: React.CSSProperties;
    tooltip?: string;
    hasDivider?: boolean;
    width?: number;
    disabled?: boolean;
    avatar?: string;
}

const ProfileMenu: React.FC<IMenu> = ({ button, anchorEl, open, onClose, onOpen, buttonStyle, tooltip, width, disabled, avatar }) => {
    const { t } = useTranslation();
    const { activeProfile, setActiveProfile, setIsSharedProfile } = useProfilesStore();
    const { salutation } = useUserSettingStore();
    const user = useUser();
    const token = user?.currentUser?.accessToken;
    const { data: profiles } = useAccountProfiles(token!);
    const loggedInUser = profiles?.find((profile) => profile.accountId === user.currentUser?.accountId);
    const handleLogout = () => {
        user?.logout();
    };

    const handleSwitchAccount = (accountId: string | undefined) => {
        setActiveProfile(accountId!);

        if (user.currentUser?.accountId !== accountId) {
            setIsSharedProfile(true);
        } else if (user.currentUser?.accountId === accountId) {
            setIsSharedProfile(false);
        }
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('profile')}>
                <span>
                    <IconButton name={tooltip} data-testid="user-button" onClick={onOpen} sx={buttonStyle} disabled={disabled} aria-label={tooltip}>
                        {button}
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                sx={{
                    mt: '45px',
                    '& .MuiPaper-root': {
                        width: width,
                    },
                }}
                id="menu-user"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={onClose}
            >
                <Stack direction={'column'} maxWidth={400} minWidth={300} justifyContent={'space-between'}>
                    <Stack direction={'row'} mb={'5px'}>
                        <Box
                            ml={3}
                            my={1}
                            height={40}
                            width={40}
                            borderRadius={100}
                            sx={{
                                backgroundColor: theme.palette.grey[400],
                                backgroundImage: `url(${avatar})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            color={'common.white'}
                        >
                            {!avatar && (
                                <>
                                    {loggedInUser?.firstName?.[0] ?? ''}
                                    {loggedInUser?.lastName?.[0] ?? ''}
                                </>
                            )}
                        </Box>
                        <Stack justifyContent={'center'}>
                            <Typography fontWeight={500} fontSize={14} px={2}>
                                {tooltip}
                            </Typography>
                            <Typography variant="body2" fontWeight={400} fontSize={14} px={2} color={theme.palette.grey[200]}>
                                {salutation.email}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Divider />
                    <Stack>
                        <Typography px={3} py={1} fontSize={14} fontWeight={400} height={40}>
                            {t('switch_profile')}
                        </Typography>
                        {profiles?.map((profile) => (
                            <Stack
                                key={profile.accountId}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{ backgroundColor: activeProfile === profile.accountId ? theme.palette.grey[50] : 'inherit' }}
                            >
                                <MenuItem
                                    sx={{ py: 2, fontSize: 14, fontWeight: 400, height: 44, width: '100%' }}
                                    onClick={() => {
                                        onClose();
                                        handleSwitchAccount(profile.accountId);
                                    }}
                                >
                                    <Box
                                        ml={2}
                                        mr={2}
                                        height={24}
                                        width={24}
                                        fontSize={12}
                                        borderRadius={100}
                                        sx={{
                                            backgroundColor: theme.palette.grey[400],
                                            backgroundImage: `url(${profile.avatar})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        color={'common.white'}
                                    >
                                        {profile.firstName ? profile.firstName[0] : ''}
                                        {profile.lastName ? profile.lastName[0] : ''}
                                    </Box>
                                    {`${profile.firstName} ${profile.lastName}`}
                                    {activeProfile === profile.accountId && <Box component={'img'} src={ActiveIcon} ml={8} />}
                                </MenuItem>
                            </Stack>
                        ))}
                        <Stack mt={'-1px'}>
                            <Divider />
                        </Stack>
                        <MenuItem
                            sx={{ px: 3, mb: '-7px', py: 2, fontSize: 14, fontWeight: 400, height: 44 }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleLogout();
                            }}
                        >
                            {t('common.logout_button_label')}
                        </MenuItem>
                    </Stack>
                </Stack>
            </Menu>
        </Box>
    );
};

export default ProfileMenu;
