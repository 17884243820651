import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import * as documentsApi from 'src/api/documentsApi';
import { IDocument } from '@interfaces/IDocument';
import { useWizardStore, WizardFormData } from '@stores/useWizzardStore';
import useProfilesStore from 'src/stores/useProfilesStore';
import templates from '../../../templates.json';
import { useNotification } from '@contexts/NotificationProvider';
import { useTranslation } from 'react-i18next';

export const DOCUMENTS_QUERY_KEY = 'documents';
export const DOCUMENT_THUMBNAIL_QUERY_KEY = 'documentThumbnail';
export const HOSPITAL_CHECKLIST_QUERY_KEY = 'hospitalChecklist';

export const useUserDocuments = (token: string) => {
    const activeProfile = useProfilesStore.getState().activeProfile ?? '';
    const isShared = useProfilesStore.getState().isSharedProfile ?? false;
    const profileId = isShared ? activeProfile : '';

    return useQuery({
        queryKey: [DOCUMENTS_QUERY_KEY],
        queryFn: async () => {
            return await documentsApi.getUserDocuments(token, profileId);
        },
        enabled: !!token,
    });
};

export const useDocumentById = (
    id: string,
    token: string,
    currentActiveWizard?: string,
    options?: Omit<UseQueryOptions<IDocument, unknown, IDocument, [string, string, string]>, 'queryKey' | 'queryFn'>,
) => {
    const activeProfile = useProfilesStore.getState().activeProfile ?? '';
    const isShared = useProfilesStore.getState().isSharedProfile ?? false;
    const profileId = isShared ? activeProfile : '';
    const { updateWizardData } = useWizardStore();

    return useQuery({
        queryKey: [DOCUMENTS_QUERY_KEY, 'detail', id],
        queryFn: async () => {
            const data = await documentsApi.getDocumentById(id, token, profileId);

            if (data?.metadata?.docType && currentActiveWizard) {
                const docType = data.metadata.docType as keyof WizardFormData[keyof WizardFormData];
                if (data.payload) {
                    updateWizardData(
                        data.payload as WizardFormData[keyof WizardFormData][typeof docType],
                        docType,
                        currentActiveWizard as keyof WizardFormData,
                    );
                }
            }

            return data;
        },
        enabled: !!id && !!token && !!currentActiveWizard,
        ...options,
    });
};

export const useCreateDocument = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            step,
            payload,
            token,
        }: {
            step: keyof WizardFormData[keyof WizardFormData];
            payload: WizardFormData[keyof WizardFormData][typeof step];
            token: string;
        }) => {
            return await documentsApi.createDocument(step, payload, token);
        },
        onSuccess: (data, variables) => {
            const filledKeys = Object.keys(variables.payload).filter((key) => variables.payload[key] !== '');

            const newDocument = {
                id: data.id,
                metadata: {
                    docType: variables.step,
                    ...('validTo' in variables && typeof variables.validTo === 'string' ? { docReviewDate: variables.validTo } : {}),
                },
                payloadState: {
                    filled: filledKeys,
                },
            };

            queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) => {
                return [...oldData, newDocument as IDocument];
            });

            void queryClient.invalidateQueries({
                queryKey: [DOCUMENTS_QUERY_KEY],
                exact: true,
                refetchType: 'none',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_create_document'), 'error');
            console.debug('Create document error:', error);
        },
    });
};

export const useUpdateDocument = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            id,
            step,
            payload,
            token,
        }: {
            id: string;
            step: keyof WizardFormData[keyof WizardFormData];
            payload: WizardFormData[keyof WizardFormData][typeof step];
            token: string;
        }) => {
            return await documentsApi.updateDocument(id, step, payload, token);
        },
        onSuccess: (_, variables) => {
            const filledKeys = Object.keys(variables.payload).filter((key) => variables.payload[key] !== '');

            queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) =>
                oldData.map((doc) =>
                    doc.id === variables.id
                        ? {
                              ...doc,
                              payloadState: {
                                  ...doc.payloadState,
                                  filled: filledKeys,
                              },
                              payload: variables.payload,
                          }
                        : doc,
                ),
            );

            queryClient.setQueryData([DOCUMENTS_QUERY_KEY, 'detail', variables.id], (oldData: IDocument | undefined) => {
                if (!oldData) return oldData;

                return {
                    ...oldData,
                    payloadState: {
                        ...oldData.payloadState,
                        filled: filledKeys,
                    },
                    payload: variables.payload,
                };
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_update_document'), 'error');
            console.debug('Update document error:', error);
        },
    });
};

export const useDeleteDocument = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const { clearDocumentData } = useWizardStore();

    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string }) => {
            return await documentsApi.deleteDocument(id, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) => {
                const docToDelete = oldData.find((doc) => doc.id === variables.id);
                const docType = docToDelete?.metadata.docType;

                const docWizardFromTemplate = templates.find((template) => template.metadata.id === docType)?.metadata.category;

                if (docType && docWizardFromTemplate) {
                    clearDocumentData(docType as keyof WizardFormData[keyof WizardFormData], docWizardFromTemplate as keyof WizardFormData);
                }

                return oldData.filter((doc) => doc.id !== variables.id);
            });

            queryClient.removeQueries({
                queryKey: [DOCUMENTS_QUERY_KEY, 'detail', variables.id],
            });

            void queryClient.invalidateQueries({
                queryKey: [DOCUMENTS_QUERY_KEY],
                refetchType: 'all',
            });

            queryClient.setQueryData([HOSPITAL_CHECKLIST_QUERY_KEY], (oldData: IDocument[] = []) => {
                return oldData.filter((item) => item.id !== variables.id);
            });

            void queryClient.invalidateQueries({
                queryKey: [HOSPITAL_CHECKLIST_QUERY_KEY],
                refetchType: 'all',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete_document'), 'error');
            console.debug('Delete document error:', error);
        },
    });
};

export const useGetFileUploadUrl = () => {
    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string }) => {
            return await documentsApi.getFileUploadUrl(id, token);
        },
    });
};

export const useUploadDocumentFile = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ url, file, docType, id }: { url: string; file: File; docType: string; id?: string }) => {
            await documentsApi.uploadDocumentFile(url, file);
            return { docType, id };
        },
        onSuccess: (result) => {
            queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) => {
                return oldData.map((doc) => {
                    if (doc.metadata.docType === result.docType) {
                        return { ...doc, isFileUploaded: true };
                    }
                    return doc;
                });
            });

            if (result.id) {
                queryClient.setQueryData([DOCUMENTS_QUERY_KEY, 'detail', result.id], (oldData: IDocument | undefined) => {
                    if (!oldData) return oldData;
                    return {
                        ...oldData,
                        isFileUploaded: true,
                        isFileProcessing: true,
                    };
                });
            }

            const documentsWithThisType = queryClient
                .getQueriesData<IDocument>({
                    predicate: (query) => query.queryKey[0] === DOCUMENTS_QUERY_KEY && query.queryKey[1] === 'detail',
                })
                .filter(([, data]) => data?.metadata?.docType === result.docType);

            documentsWithThisType.forEach(([queryKey, data]) => {
                queryClient.setQueryData(queryKey, {
                    ...data,
                    isFileUploaded: true,
                    isFileProcessing: true,
                });
            });

            void queryClient.invalidateQueries({
                queryKey: [DOCUMENTS_QUERY_KEY],
                exact: true,
                refetchType: 'none',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_upload_document_file'), 'error');
            console.debug('Upload document file error:', error);
        },
    });
};

export const useDeleteDocumentFile = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string; docType: string }) => {
            return await documentsApi.deleteDocumentFile(id, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) =>
                oldData.map((doc) => (doc.metadata.docType === variables.docType ? { ...doc, isFileUploaded: false } : doc)),
            );

            const documentWithThisType = queryClient
                .getQueriesData<IDocument>({
                    predicate: (query) => query.queryKey[0] === DOCUMENTS_QUERY_KEY && query.queryKey[1] === 'detail',
                })
                .find(([, data]) => data?.metadata?.docType === variables.docType);

            if (documentWithThisType) {
                const [queryKey, data] = documentWithThisType;
                queryClient.setQueryData(queryKey, {
                    ...data,
                    isFileUploaded: false,
                });
            }

            void queryClient.invalidateQueries({
                queryKey: [DOCUMENTS_QUERY_KEY],
                exact: true,
                refetchType: 'none',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete_document_file'), 'error');
            console.debug('Delete document file error:', error);
        },
    });
};

export const useDocumentThumbnail = (id: string, token: string) => {
    const queryClient = useQueryClient();
    const cachedData = queryClient.getQueryData<string>([DOCUMENT_THUMBNAIL_QUERY_KEY, id]);
    const activeProfile = useProfilesStore.getState().activeProfile ?? '';
    const isShared = useProfilesStore.getState().isSharedProfile ?? false;
    const profileId = isShared ? activeProfile : '';

    return useQuery({
        queryKey: [DOCUMENT_THUMBNAIL_QUERY_KEY, id],
        queryFn: async () => {
            const url = await documentsApi.getDocumentThumbnail(id, token, profileId);

            const isNewUrl = url !== cachedData;

            if (url) {
                if (isNewUrl) {
                    setTimeout(() => {
                        queryClient.setQueryData([DOCUMENTS_QUERY_KEY, 'detail', id], (oldData: IDocument | undefined) => {
                            if (!oldData) return oldData;
                            return {
                                ...oldData,
                                isFileUploaded: true,
                                isFileProcessing: false,
                            };
                        });

                        queryClient.setQueryData([DOCUMENTS_QUERY_KEY], (oldData: IDocument[] = []) => {
                            return oldData.map((doc) => {
                                if (doc.id === id) {
                                    return {
                                        ...doc,
                                        isFileUploaded: true,
                                        isFileProcessing: false,
                                    };
                                }
                                return doc;
                            });
                        });
                    }, 0);
                } else {
                    queryClient.removeQueries({
                        queryKey: [DOCUMENT_THUMBNAIL_QUERY_KEY, id],
                    });
                }
            } else {
                console.debug('No thumbnail URL returned', { id });
            }

            return url;
        },
        enabled: !!id && !!token,
        refetchInterval: () => {
            const doc = queryClient.getQueryData<IDocument>([DOCUMENTS_QUERY_KEY, 'detail', id]);
            return doc?.isFileProcessing ? 3000 : false;
        },
    });
};

export const useHospitalChecklist = (token: string) => {
    const activeProfile = useProfilesStore.getState().activeProfile ?? '';
    const isShared = useProfilesStore.getState().isSharedProfile ?? false;
    const profileId = isShared ? activeProfile : '';

    return useQuery({
        queryKey: [HOSPITAL_CHECKLIST_QUERY_KEY],
        queryFn: async () => {
            return await documentsApi.getHospitalChecklist(token, profileId);
        },
        enabled: !!token,
    });
};

export const useDeleteHospitalChecklistItem = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string }) => {
            return await documentsApi.deleteHospitalChecklistItem(id, token);
        },
        onSuccess: (_, variables) => {
            void queryClient.invalidateQueries({
                queryKey: [HOSPITAL_CHECKLIST_QUERY_KEY],
                refetchType: 'none',
            });
            queryClient.setQueryData([HOSPITAL_CHECKLIST_QUERY_KEY], (oldData: IDocument[] = []) => {
                return oldData.filter((item) => item.id !== variables.id);
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete'), 'error');
            console.debug('Failed to delete error:', error);
        },
    });
};

export const useCreateHospitalChecklistItem = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            payload,
            token,
        }: {
            payload: { title: string; description: string; location: string; accessories: { title: string; location: string }[] };
            token: string;
        }) => {
            const response = await documentsApi.createHospitalChecklistItem(payload, token);
            return response;
        },
        onSuccess: (data, variables) => {
            const newDocument = {
                id: data,
                ...variables.payload,
            };

            queryClient.setQueryData([HOSPITAL_CHECKLIST_QUERY_KEY], (oldData: []) => {
                return [...oldData, newDocument];
            });

            void queryClient.invalidateQueries({
                queryKey: [HOSPITAL_CHECKLIST_QUERY_KEY],
                exact: true,
                refetchType: 'none',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_create'), 'error');
            console.debug('Failed to create error:', error);
        },
    });
};

export const useUpdateHospitalChecklistItem = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            id,
            payload,
            token,
        }: {
            id: string;
            payload: { title: string; description: string; location: string; accessories: { title: string; location: string }[] };
            token: string;
        }) => {
            return await documentsApi.updateHospitalChecklistItem(id, payload, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([HOSPITAL_CHECKLIST_QUERY_KEY], (oldData: IDocument[] = []) =>
                oldData.map((doc) =>
                    doc.id === variables.id
                        ? {
                              ...doc,
                              ...variables.payload,
                          }
                        : doc,
                ),
            );

            void queryClient.invalidateQueries({
                queryKey: [HOSPITAL_CHECKLIST_QUERY_KEY],
                exact: true,
                refetchType: 'none',
            });
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_update'), 'error');
            console.debug('Failed to update error:', error);
        },
    });
};
