import { useQuery } from '@tanstack/react-query';
import * as profilesApi from 'src/api/profilesApi';
import { useContacts } from '../contacts/queries';

export const profileKeys = {
    all: ['profiles'] as const,
    account: () => [...profileKeys.all, 'account'] as const,
};

export const useAccountProfiles = (token: string, options = {}) => {
    const { data: contacts } = useContacts(token);

    return useQuery({
        queryKey: profileKeys.account(),
        queryFn: async () => {
            const data = await profilesApi.getAccountProfiles(token);

            return data.map((profile) => {
                const contact = contacts?.find((contact) => contact.id === profile.contactId) ?? { avatar: '', id: '' };
                return {
                    ...profile,
                    avatar: contact.avatar,
                    contactId: profile.contactId ?? '',
                };
            });
        },
        enabled: false,
        ...options,
    });
};
