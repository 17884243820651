export interface HospitalChecklistItem {
    id?: string;
    title: string;
    description: string;
    location: string;
    accessories: { title: string; location: string }[];
}

import { create } from 'zustand';
import { IPersonalData } from '@interfaces/IPersonalData';
import { IDirectives } from '@interfaces/IDirectives';
import { personalDataInitialState } from '../initialStates/personalDataInitialState';
import { IVehicles } from '@interfaces/IVehicles';
import { INursing } from '@interfaces/INursing';
import { IDeath } from '@interfaces/IDeath';
import templates from '../templates.json';
import { directivesDataInitialState } from '../initialStates/directivesDataInitialState';
import { vehicleDataInitialState } from '../initialStates/vehicleDataInitialState';
import { nursingDataInitialState } from '../initialStates/nursingDataInitialState';
import { devtools } from 'zustand/middleware';
import { useUserSettingStore } from './useUserSettingsStore';
import { deathDataInitialState } from '../initialStates/deathDataInitialState';
import { IHealth } from '@interfaces/IHealth';
import { healthDataInitialState } from '../initialStates/healthDataInitialState';
import { IDocument } from '@interfaces/IDocument';

interface Field {
    type: string;
    name: string;
    label: string;
    required: boolean;
    size: number;
    position: number;
    options: string[];
    description: string;
    hasEmptySpace: null;
}

interface GroupData {
    groupName: string;
    groupNameDescription: string;
    fields: Field[];
}

interface Template {
    metadata: {
        id: string;
        category: string;
    };
    data: GroupData[];
}

export interface WizardFormData {
    personalData: IPersonalData;
    directives: IDirectives;
    vehicles: IVehicles;
    nursing: INursing;
    death: IDeath;
    health: IHealth;
}

interface InitialQuestions {
    gender: string;
    relationship: string;
}

interface InfoConfirmed {
    directivesIntro: boolean;
    directivesAppInfo: boolean;
}

interface Salutation {
    termsAndConditionsAccepted: boolean;
    firstName: string;
    lastName: string;
}

interface WizardStore {
    salutation: Salutation;
    initialQuestions: InitialQuestions;
    infoConfirmed: InfoConfirmed;
    formData: WizardFormData;
    directivesDataNotRelevant: string[];
    vehiclesDataNotRelevant: string[];
    activeWizard: keyof WizardFormData | '';
    fileExists: boolean;
    fileUrl: string | null;

    updateWizardData: <K extends keyof WizardFormData[keyof WizardFormData]>(
        data: WizardFormData[keyof WizardFormData][K],
        step: K,
        wizard?: keyof WizardFormData,
    ) => void;
    updateInfoConfirmed: (data: Partial<InfoConfirmed>) => void;
    updateSalutation: (data: Partial<Salutation>) => void;
    isStepDataFilled: (step: keyof WizardFormData[keyof WizardFormData]) => boolean;
    calculateDataProgress: (wizard: string, userDocuments: IDocument[]) => { stepsProgress: { name: string; progress: number }[]; totalProgress: number };
    setActiveWizard: (wizard: keyof WizardFormData | '') => void;
    updateHospitalChecklistItems: (items: HospitalChecklistItem[]) => void;
    clearFileValues: () => void;
    setFileValues: (fileExists: boolean, fileUrl: string | null) => void;
    clearDocumentData: (docType: keyof WizardFormData[keyof WizardFormData], wizardType: keyof WizardFormData) => void;
}

export const useWizardStore = create<WizardStore>()(
    devtools((set, get) => ({
        salutation: {
            firstName: '',
            lastName: '',
            termsAndConditionsAccepted: false,
        },
        initialQuestions: {
            gender: 'Choose',
            relationship: 'Choose',
        },
        infoConfirmed: {
            directivesIntro: false,
            directivesAppInfo: false,
        },
        formData: {
            personalData: personalDataInitialState,
            directives: directivesDataInitialState,
            vehicles: vehicleDataInitialState,
            nursing: nursingDataInitialState,
            death: deathDataInitialState,
            health: healthDataInitialState,
        },
        directivesDataNotRelevant: [],
        vehiclesDataNotRelevant: [],
        activeWizard: '',
        fileExists: false,
        fileUrl: null,

        updateWizardData: <K extends keyof WizardFormData[keyof WizardFormData]>(
            data: WizardFormData[keyof WizardFormData][K],
            step: K,
            wizard?: keyof WizardFormData,
        ) => {
            const activeWizard = wizard ?? get().activeWizard;
            if (!activeWizard) return;

            set((state) => ({
                formData: {
                    ...state.formData,
                    [activeWizard]: {
                        ...state.formData[activeWizard],
                        [step]: {
                            ...(state.formData[activeWizard][step] as object),
                            ...(data as object),
                        },
                    },
                },
            }));
        },

        clearDocumentData: (docType: keyof WizardFormData[keyof WizardFormData], wizardType: keyof WizardFormData) => {
            set((state) => ({
                formData: {
                    ...state.formData,
                    [wizardType]: {
                        ...state.formData[wizardType],
                        [docType]: {},
                    },
                },
            }));
        },

        updateHospitalChecklistItems: (items: HospitalChecklistItem[]) => {
            set((state) => ({
                formData: {
                    ...state.formData,
                    nursing: {
                        ...state.formData.nursing,
                        hospitalChecklist: {
                            ...((state.formData.nursing.hospitalChecklist as Record<string, unknown>) || {}),
                            items,
                        },
                    },
                },
            }));
        },

        updateInfoConfirmed: (data) =>
            set((state) => ({
                infoConfirmed: {
                    ...state.infoConfirmed,
                    ...data,
                },
            })),

        updateSalutation: (data) =>
            set((state) => ({
                salutation: {
                    ...state.salutation,
                    ...data,
                },
            })),

        isStepDataFilled: (step: keyof WizardFormData[keyof WizardFormData]) => {
            if (step === 'initialQuestions') {
                const stepData = get().initialQuestions;
                return Object.values(stepData).every((value) => value !== 'Choose');
            } else {
                const activeWizard = get().activeWizard;
                if (!activeWizard) return false;
                const stepData = get().formData[activeWizard][step] as object;
                return Object.entries(stepData).every(([, value]) => {
                    return value !== '';
                });
            }
        },

        calculateDataProgress: (wizard: string, userDocuments: IDocument[] = []) => {
            const templateDocuments = templates.filter((item) => item.metadata.category === wizard) as Template[];
            const notRelevantSteps = useUserSettingStore.getState().userSettingsDetails.notRelevantSteps ?? [];
            const userTotalSteps = useUserSettingStore.getState().userSettingsDetails.userTotalSteps ?? [];
            const relevantTemplateDocuments = templateDocuments.filter((template) => !notRelevantSteps.includes(template.metadata.id));
            const allUserDocumentsByWizard = relevantTemplateDocuments.map((template) => {
                const userDocument = userDocuments?.find((doc) => doc.metadata.docType === template.metadata.id);

                const totalFields = Array.isArray(template.data)
                    ? template.data.reduce((total, group) => {
                          if (group && Array.isArray(group.fields)) {
                              return total + group.fields.length;
                          }
                          return total;
                      }, 0)
                    : 0;

                return {
                    ...template,
                    filled: Boolean(userDocument?.payloadState?.filled),
                    wizard: template.metadata.category,
                    isFileUploaded: Boolean(userDocument?.isFileUploaded),
                    totalFields,
                    filledFields: userDocument?.payloadState?.filled?.length ?? 0,
                };
            });

            const filledSteps = allUserDocumentsByWizard.filter((item) => item.filled);

            const stepsProgress = filledSteps.map((step) => {
                const fieldProgress = step.totalFields > 0 ? Math.round((step.filledFields / step.totalFields) * 100) / 2 : 0;

                return {
                    name: step.metadata.id,
                    progress: fieldProgress + (step.isFileUploaded ? 50 : 0),
                };
            });

            const totalSteps = userTotalSteps;
            const totalProgress = totalSteps > 0 ? parseFloat((stepsProgress.reduce((total, step) => total + step.progress, 0) / totalSteps).toFixed(1)) : 0;

            return {
                stepsProgress,
                totalProgress,
            };
        },

        setActiveWizard: (wizard) => {
            set({ activeWizard: wizard });
        },

        clearFileValues: () => set({ fileExists: false, fileUrl: null }),

        setFileValues: (fileExists, fileUrl) => set({ fileExists, fileUrl }),
    })),
);
