import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import { IContact, ContactsState, ContactsActions } from '@interfaces/contacts/contacts';

export const useContactsStore = create<ContactsState & ContactsActions>()(
    immer(
        devtools((set) => ({
            accountContact: null,
            selectedContact: null,
            editContact: null,
            isLoading: false,
            error: null,
            inviter: null,

            setSelectedContact: (contact) => {
                set({ selectedContact: contact });
            },

            setEditContact: (contact) => {
                set({ editContact: contact });
            },

            setEditAccountContact: (contact: IContact) => {
                set({ accountContact: contact });
            },

            setInviter: (inviter) => {
                set({ inviter: inviter });
            },
        })),
    ),
);

export default useContactsStore;
