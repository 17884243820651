import { get as getData } from '../common/api';
import { UserSettingsDetails } from '@stores/useUserSettingsStore';

export const fetchUserSettingsDetails = async (token: string): Promise<UserSettingsDetails> => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts/settings`;
    return await getData<UserSettingsDetails>(url, token);
};

export const getAccount = async (token: string) => {
    const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts`;
    return await getData(url, token);
};
