import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as contactsApi from 'src/api/contactsApi';
import { AvatarUrl, IContact } from '@interfaces/contacts/contacts';
import { updatePermissions } from 'src/api/contactsApi';
import { useNotification } from '@contexts/NotificationProvider';
import { useTranslation } from 'react-i18next';

export const CONTACTS_QUERY_KEY = 'contacts';
export const CONTACT_AVATAR_QUERY_KEY = 'contactAvatar';

export const useContacts = (token: string) => {
    return useQuery({
        queryKey: [CONTACTS_QUERY_KEY],
        queryFn: async () => {
            const data = await contactsApi.getContacts(token);
            const contacts = await Promise.all(
                data.map(async (contact) => {
                    const avatar = contact.hasAvatar ? await contactsApi.getAvatar(contact.id!, token) : '';
                    return {
                        ...contact,
                        avatar: contact.hasAvatar ? (avatar ? avatar : '') : '',
                    };
                }),
            );

            return contacts;
        },
        enabled: !!token,
    });
};

export const useAccountContact = (token: string) => {
    const { data: contacts } = useContacts(token);

    return {
        data: contacts?.find((contact) => contact.contactType === 'ACCOUNT') ?? null,
    };
};

export const useAddContact = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            contactData,
            token,
            imageUrl,
            uploadImage,
        }: {
            contactData: Omit<IContact, 'id'>;
            token: string;
            imageUrl?: string;
            uploadImage?: File;
        }) => {
            const newContact = await contactsApi.addContact(contactData, token);

            if (imageUrl && uploadImage) {
                const contactUploadUrl = newContact._links.uploadLink.url;
                await contactsApi.uploadContactAvatar(contactUploadUrl, uploadImage);
            }

            return { ...contactData, ...newContact, hasAvatar: !!imageUrl, avatar: imageUrl ?? '' };
        },
        onSuccess: (newContact) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) => [...oldData, newContact]);
        },
        onError: (error: Error) => {
            if (error.message.includes('There is already a contact with email')) {
                showNotification(t('errors.contact_already_exists'), 'error');
                return;
            }
            showNotification(t('errors.failed_to_create_contact'), 'error');
            console.debug('Create contact error:', error);
        },
    });
};

export const useUpdateContact = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, updatedContact, token }: { id: string; updatedContact: Partial<IContact>; token: string }) => {
            return await contactsApi.updateContact(id, updatedContact, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) =>
                oldData.map((contact) => (contact.id === variables.id ? { ...contact, ...variables.updatedContact } : contact)),
            );
        },
        onError: (error: Error) => {
            if (error.message.includes('There is already a contact with email')) {
                showNotification(t('errors.contact_already_exists'), 'error');
                return;
            }
            showNotification(t('errors.failed_to_update_contact'), 'error');
            console.debug('Update contact error:', error);
        },
    });
};

export const useDeleteContact = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string }) => {
            return await contactsApi.deleteContact(id, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) => oldData.filter((contact) => contact.id !== variables.id));
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete_contact'), 'error');
            console.debug('Delete contact error:', error);
        },
    });
};

export const useUploadContactAvatar = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ url, image, contactId }: { url: string; image: File; contactId: string }) => {
            await contactsApi.uploadContactAvatar(url, image);
            return { contactId, imageUrl: URL.createObjectURL(image) };
        },
        onSuccess: (result) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) =>
                oldData.map((contact) => (contact.id === result.contactId ? { ...contact, avatar: result.imageUrl } : contact)),
            );
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_upload_avatar'), 'error');
            console.debug('Avatar upload error:', error);
        },
    });
};

export const useDeleteContactAvatar = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ contactId, token }: { contactId: string; token: string }) => {
            return await contactsApi.deleteContactAvatar(contactId, token);
        },
        onSuccess: (_, { contactId }) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) =>
                oldData.map((contact) => (contact.id === contactId ? { ...contact, avatar: '', hasAvatar: false } : contact)),
            );
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete_avatar'), 'error');
            console.debug('Avatar delete error:', error);
        },
    });
};

export const useGetContactAvatarUploadUrl = () => {
    return useMutation({
        mutationFn: async ({ contactId, token }: { contactId: string; token: string }): Promise<AvatarUrl> => {
            return await contactsApi.getContactAvatarUploadUrl(contactId, token);
        },
    });
};

export const useSendInvitation = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ id, message, assignedRole, token }: { id: string; message: string; assignedRole: string; token: string }) => {
            const sendMessage = { message, assignedRole };
            return await contactsApi.sendInvitation(id, sendMessage, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) =>
                oldData.map((contact) => (contact.id === variables.id ? { ...contact, invitationStatus: 'PENDING' } : contact)),
            );
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_send_invitation'), 'error');
            console.debug('Send invitation error:', error);
        },
    });
};

export const useGetInviterByInvitationCode = () => {
    return useMutation({
        mutationFn: async ({ invitationCode, token }: { invitationCode: string; token: string }) => {
            return await contactsApi.getInviterByInvitationCode(invitationCode, token);
        },
    });
};

export const useRespondInvitation = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: async ({ respond, invitationCode, token }: { respond: string; invitationCode: string; token: string }) => {
            return await contactsApi.respondInvitation(respond, invitationCode, token);
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_respond_invitation'), 'error');
            console.debug('Respond invitation error:', error);
        },
    });
};
export const useUpdatePermissions = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    return useMutation({
        mutationFn: async ({ id, role, token }: { id: string; role: string; token: string }) => {
            return await updatePermissions(id, role, token);
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_update_permissions'), 'error');
            console.debug('Failed to update permissions error:', error);
        },
    });
};

export const useDeletePermissions = () => {
    const { showNotification } = useNotification();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, token }: { id: string; token: string }) => {
            return await contactsApi.deletePermissions(id, token);
        },
        onSuccess: (_, variables) => {
            queryClient.setQueryData([CONTACTS_QUERY_KEY], (oldData: IContact[] = []) =>
                oldData.map((contact) => (contact.id === variables.id ? { ...contact, role: null, invitationStatus: undefined } : contact)),
            );
        },
        onError: (error: Error) => {
            showNotification(t('errors.failed_to_delete_permissions'), 'error');
            console.debug('Failed to delete permissions error:', error);
        },
    });
};
