import { INursing } from '@interfaces/INursing';

export const nursingDataInitialState: INursing = {
    careNeeds: {
        checklist: {
            'care_needs_step.submit_application': false,
            'care_needs_step.comparison_list': false,
            'care_needs_step.advice': false,
            'care_needs_step.assessment': false,
            'care_needs_step.care_diary': false,
            'care_needs_step.present_during_assessment': false,
            'care_needs_step.assess_long_term_care': false,
            'care_needs_step.inpatient_facility': false,
        },
    },
    biographySheet: {
        accordionData: Array.from({ length: 14 }, () => ({
            preferences: '',
            dislikes: '',
            habits: '',
        })),
    },
    hospitalChecklist: {
        items: [],
    },
    careLeave: {
        accordionData: [],
    },
};
