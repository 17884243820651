import { Box, Button, styled, SxProps, Theme } from '@mui/material';

interface IButton {
    /** Button variant */
    variant?: 'text' | 'contained' | 'outlined';
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    /** Button contents */
    text: string;
    disabled?: boolean;
    onClick?: () => void;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    styles?: SxProps<Theme>;
    children?: React.ReactNode;
}

const ButtonStyled = styled(Button)({
    width: '100%',
    height: '50px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '700',
    whiteSpace: 'nowrap',
});

const ButtonComponent: React.FC<IButton> = ({ variant, color, text, disabled, onClick, startIcon, endIcon, styles, children }) => {
    return (
        <ButtonStyled
            sx={{ color: color === 'info' ? 'white' : '', ...styles }}
            variant={variant ?? 'contained'}
            color={color}
            disabled={disabled}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
        >
            {children && (
                <Box mr={2} mt={1}>
                    {children}
                </Box>
            )}

            {text}
        </ButtonStyled>
    );
};

export default ButtonComponent;
